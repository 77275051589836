import request from '@/utils/request'

export function listPage(params) {
    return request({
        url:'/api/loaclstock/listPage',
        method:'get',
        params
    });
}

export function pageInit() {
    return request({
        url:'/api/loaclstock/pageInit',
        method:'get',
    });
}

export function delGoods(id) {
    return request({
        url:`/api/loaclstock/${id}`,
        method:'delete',
    });
}

export function updateInit(id) {
    return request({
        url:`/api/loaclstock/updateInit/${id}`,
        method:'get',
    });
}

export function updateLoaclstock(data) {
    return request({
        url:`/api/loaclstock`,
        method:'put',
        data
    });
}

