<template>
      <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
        <el-select v-model="pageInfo.storageType" clearable style="width:200px;margin-right:10px" placeholder="请选择仓库类型" @change="find">
            <el-option
            v-for="item in localStorageTypeList"
            :key="item.id"
            :label="item.value" 
            :value="item.id">
            </el-option>
        </el-select>
        <el-input v-model="pageInfo.keyword" style="width:200px;margin-right:10px" placeholder="请输入仓库名称或代码" @keyup.enter.native = "find"></el-input>
        <el-button type='primary' @click="find">查询</el-button>
        <el-button @click="reset">重置</el-button><br><br>
        <el-button @click="openInventoryImportant">库存导入</el-button>
        <el-table
          border
          :data="tableData"
          style="width: 100%;margin-top:20px"
          header-cell-style="background:#F5F5F5;font-size:14px;text-align:center"
          cell-style="text-align:center;font-size:14px;">
          <el-table-column
            prop="prop"
            label="商品信息"
            min-width="120">
            <template slot-scope="scope">
                <div style="display:flex;align-items:center">
                    <div style='width:50px;height:50px;margin-right:10px'>
                        <img v-if="scope.row.productImageUrl" :src="scope.row.productImageUrl" alt="" width="100%"/>
                        <div v-else style="width:100%;height:100%;background:#E8E8E8">
                            <p class="el-icon-picture-outline" style="line-height:50px;text-align:center"></p>
                        </div>
                    </div>
                    <!-- 字 -->
                    <div style="width:150px">
                        <p class="infoText" style="text-align:left">{{scope.row.productName}}</p>
                    </div>
                </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="sku"
              label="SKU"
              min-width="100">
          </el-table-column>
          <el-table-column
              prop="storageName"
              label="仓库"
              min-width="100">
          </el-table-column>
          <el-table-column
              prop="productTotal"
              label="商品总量"
              min-width="100">
          </el-table-column>
          <el-table-column
              prop="goodNumber"
              label="良品量"
              min-width="100">
          </el-table-column>
          <el-table-column
              prop="badNumber"
              label="次品量"
              min-width="100">
          </el-table-column>
          <el-table-column
              prop="value"
              label="货值"
              min-width="100">
              <template slot-scope="scope">
                <span>{{scope.row.valueCurrency}}{{scope.row.value}}</span>
              </template>
          </el-table-column>
          <el-table-column
              label="操作"
              min-width="80">
              <template slot-scope="scope">
                <el-button type="text" @click="update(scope.row.id)">编辑</el-button>
                <el-button type="text" @click="delItem(scope.row.id)">删除</el-button>
              </template>
          </el-table-column>
        </el-table>
        <div style="text-align:center;margin:19px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <el-dialog
          title="修改库存"
          :visible.sync="updateDialog"
          width="550px"
          :before-close="updateDialogClose">
          <div style="margin-top:-20px">
            <el-select v-model="updateInfo.stockType" style="width:90px">
              <el-option
                v-for="item in stockTypeList"
                :key="item.id"
                :label="item.value"
                @click.native="change(item)"
                :value="item.id">
              </el-option>
            </el-select>
            <el-form ref="addInfo" :model="addInfo" label-width="80px" style="width:300px;margin:30px auto 0">
              <el-form-item label="仓库">
                <el-input disabled v-model="info.storageName" placeholder="请输入仓库"></el-input>
              </el-form-item>
              <el-form-item label="SKU">
                <el-input disabled v-model="info.sku" placeholder="请输入SKU"></el-input>
              </el-form-item>
              <el-form-item label="现存">
                <el-input disabled v-model="number" placeholder="请输入现存数量"></el-input>
              </el-form-item>
              <el-form-item label="修改">
                <el-select v-model="updateInfo.operateType" style="width:80px;margin-right:10px">
                  <el-option
                    v-for="item in operateTypeList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-input v-model="updateInfo.number" type="number" style="width:130px" placeholder=""></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div slot="footer">
            <el-button @click="updateDialogClose">取 消</el-button>
            <el-button type="primary" @click="sure" :loading='buttonLoading'>确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog
            title="库存导入"
            :visible.sync="inventoryImportant"
            width="25%"
            :before-close="inventoryImportantClose">
            <div style="margin-top:-20px;line-height:20px;font-size:12px">
                <p>1.模板中的表头不可更改，不可删除</p>
                <p>2.导入前请在仓库列表中添加所属的仓库</p>
                <p>3.导入前请在商品列表中添加相应的商品</p>
                <p>4.若SKU已有库存信息，则不会处理该条数据</p>
                <el-button type="primary" @click="down" plain style="width:100%;margin:15px 0">下载空白模板<i class="el-icon-download"></i></el-button>
                <div style="text-align:center">
                    <el-upload
                    style="width:100%"
                    class="upload-demo"
                    :headers="mytoken"
                    :on-success="handleSuccess"
                    :file-list="fileList"
                    drag
                    :action="action"
                    multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="inventoryImportantClose">取 消</el-button>
                <el-button type="primary" @click="inventoryImportantClose">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {listPage, pageInit, delGoods, updateInit, updateLoaclstock} from '@/api/inventory.js'
export default {
  data(){
    var mytoken=window.sessionStorage.getItem('token')
    return {
      loading:false,
      updateDialog:false,
      buttonLoading:false,
      inventoryImportant:false,
      mytoken:{token:mytoken},
      tableData:[],
      pageInfo:{
        current:'',
        pageSize:'10',
        storageType:'',
        keyword:''
      },
      total:'',
      localStorageTypeList:[],
      operateTypeList:[],
      stockTypeList:[],
      updateInfo:{
        id:'',
        number:'0',
        operateType:'add',
        stockType:'good',
      },
      info:{
        storageName:'',
        sku:'',
        badNumber:'',
        goodNumber:'',
      },
      number:'',
      fileList:[],
    };
  },
  created(){
    this.getPageInit()
    this.getListPage()
  },
  computed:{
      action(){
          return process.env.VUE_APP_API_URL+'/api/loaclstock/addStockFile';
      }
  },
  methods:{
    inventoryImportantClose(){
        this.inventoryImportant=false
        this.fileList=[]
        this.getListPage()
    },
    handleSuccess(res, file, fileList){
      console.log(res);
        console.log(res, file, fileList);
        if(res.code==200){
            this.$message.success(res.data.message)
            this.fileList=fileList
            if(res.data.code==300){
              let str = `<div style="max-height:200px;overflow:auto;padding-left:20px">`
                res.data.data.forEach(item=>{
                    str+= `<p style="line-height:24px">${item}</p>`
                })
                str+=`</div>`
                this.$alert(str, '库存导入失败项', {
                    dangerouslyUseHTMLString: true
                });
            }
        }else{
            this.$message.error(res.message)
            this.fileList=fileList.filter(item=>{
                return item.response.code==200;
            })
        }
    },
    down(){
      window.location.href='库存初始值设置模板.xlsx'
    },
    openInventoryImportant(){
      this.inventoryImportant=true
    },
    updateDialogClose(){
      this.updateInfo={
        id:'',
        number:'0',
        operateType:'add',
        stockType:'good',
      }
      this.updateDialog=false
    },
    sure(){
      this.buttonLoading=true
      updateLoaclstock(this.updateInfo).then(res=>{
        this.buttonLoading=false
        if(res.data.code==200){
            this.$message.success(res.data.message)
            this.updateDialogClose()
            this.getListPage()
        }else {
            this.$message.error(res.data.message)
        }
      }).catch(error=>{
        this.buttonLoading=false
      })
    },
    change(item){
      if(item.id=='good'){
        this.number=this.info.goodNumber
      }else{
        this.number=this.info.badNumber
      }
    },
    reset(){
        this.pageInfo={
          current:'',
          pageSize:'10',
          storageType:'',
          keyword:''
        }
        this.getListPage()
    },
    update(id){
      this.updateInfo.id=id
      this.loading=true
      updateInit(id).then(res=>{
        this.loading=false
        this.updateDialog=true
        console.log(res);
        this.operateTypeList=res.data.data.operateTypeList
        this.stockTypeList=res.data.data.stockTypeList
        this.info=res.data.data.LocalStock
        this.number=this.info.goodNumber
      })
    },
    delItem(id){
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.loading=true
            delGoods(id).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListPage()
                }else {
                    this.$message.erro(res.data.message)
                }
            }).catch(()=>{
                this.loading=false
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
        });
    },
    getAddInit(){
        addInit().then(res=>{
          console.log(res);
        })
    },
    getPageInit(){
        pageInit().then(res=>{
          this.localStorageTypeList=res.data.data.localStorageTypeList
        })
    },
    handleSizeChange(val){
        this.pageInfo.pageSize=val
        this.getListPage()
    },
    handleCurrentChange(val){
        this.pageInfo.current=val
        this.getListPage()
    },
    find(){
        this.pageInfo.current=1
        this.getListPage()
    },
    getListPage(){
      this.loading=true
      listPage(this.pageInfo).then(res=>{
        this.loading=false
        this.tableData=res.data.values
        this.total=res.data.pageInfo.total
      }).catch(()=>{
        this.loading=false
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
}
::v-deep .el-upload{
    width: 100%;
    .el-upload-dragger{
        width: 100%;
    }
}
::v-deep .el-upload-list__item-name{
        text-align: left;
    }
</style>